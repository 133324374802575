import PubSub from "underpub-js";
import slick from "slick-carousel";

var Carousel = function (options) {

  this.options = {
    $el: false,
    baseClass: 'el-Carousel',
  };

  $.extend(true, this.options, options);

};

Carousel.prototype.init = function () {

  var self = this;

  self.$slides = self.options.$el.find(`.${self.options.baseClass}__slides`);
  self.$arrowPrev = self.options.$el.find(`.${self.options.baseClass}__arrow--prev`);
  self.$arrowNext = self.options.$el.find(`.${self.options.baseClass}__arrow--next`);
  self.$dots = self.options.$el.find(`.${self.options.baseClass}__dots`);

  self.initCarousel();

  PubSub.subscribe('Carousel:updateData', data => {
    if (!data) return;

    self.updateData(data);
  });

};

Carousel.prototype.initCarousel = function ($carousel) {
  const self = this;

  const $slides = ($carousel && $carousel.length) ? $carousel.find(`.${self.options.baseClass}__slides`) : self.$slides;

  $slides.slick({
    prevArrow: self.$arrowPrev,
    nextArrow: self.$arrowNext,
    fade: true,
    dots: true,
    appendDots: self.$dots,
    autoplay: true,
    autoplaySpeed: 3000,
  });
}

Carousel.prototype.updateData = function (data) {
  const self = this;
  const container = data.container;
  const slides = data.slides;

  if (!container || !container.length) return;

  if (self.options.$el.parent($(container)).length) {
    const $carousel = $(container).find(self.options.$el);

    if (!$carousel.length) return;

    if (slides && slides.length) {
      self.generateSlides($carousel, slides);
    }
  }
}

Carousel.prototype.generateSlides = function ($carousel, slides) {
  const self = this;

  if (!slides || !$carousel) return;

  const $slides = $carousel.find(`.${self.options.baseClass}__slides`);

  $slides.hide();

  if ($slides.hasClass('slick-initialized')) {
    $slides.slick('slickRemove', null, null, true);
    $slides.slick("unslick");
  }

  slides.forEach(slide => {
    const slideType = slide.type;

    if (!slideType || !slideType.length) return;

    let slideElement;

    switch (slideType) {
      case "image":
        slideElement = self.generateImageSlide(slide);
        break;
      case "video":
        slideElement = self.generateVideoSlide(slide);
        break;
      default:
        break;
    }

    if (slideElement && slideElement.length) {
      $slides.append(slideElement);
    }
  });

  $slides.show();
  self.initCarousel($carousel);
}

Carousel.prototype.generateImageSlide = function (data) {
  const self = this;

  if (!data) return;

  const url = data.url;
  const alt = data.alt;

  if (!url || !url.length) return;

  const slide = `<div class="${self.options.baseClass}__slide ${self.options.baseClass}__image" style="background-image: url(${url})">
    <img class="atom-ImageHidden" src="${url}" alt="${alt}" />
  </div>`;

  return slide;
}

Carousel.prototype.generateVideoSlide = function (data) {
  const self = this;

  if (!data) return;

  const url = data.url;

  if (!url || !url.length) return;

  const slide = `<div class="${self.options.baseClass}__slide ${self.options.baseClass}__video">
    <div class="${self.options.baseClass}__video__inner">
      <iframe type="text/html" src="${url}" frameborder="0" allow="autoplay"></iframe>
    </div>
  </div>`;

  return slide;
}

export default Carousel;