import PubSub from "underpub-js";
import gsap from 'gsap';

var ModalHeader = function (options) {

  this.options = {
    $el: false,
    baseClass: 'mol-ModalHeader',
  };

  $.extend(true, this.options, options);

};

ModalHeader.prototype.init = function () {

  var self = this;

  self.$navigationPrimaryItems = self.options.$el.find(`.${self.options.baseClass}__navigation-primary li`);
  self.$navigationSecondaryItems = self.options.$el.find(`.${self.options.baseClass}__navigation-secondary li`);

  self.initAnimation();
  PubSub.subscribe('ModalHeader:toggle', () => self.toggle());
  PubSub.subscribe('ModalHeader:toggleOn', () => self.toggleOn());
  PubSub.subscribe('ModalHeader:toggleOff', () => self.toggleOff());
  PubSub.subscribe('Sitewide:resize', () => self.toggleOff())

};

ModalHeader.prototype.initAnimation = function () {
  const self = this;

  self.animationTl = gsap
    .timeline({
      paused: true,
      reversed: true,
      defaults: {
        ease: "expo.out"
      },
      onComplete: () => self.toggleState(),
      onReverseComplete: () => {
        self.toggleState();
      },
    })
    .set($("body"), {
      overflow: "hidden"
    })
    .set(self.options.$el, {
      visibility: "hidden",
      opacity: 0,
    })
    .to(self.options.$el, {
      visibility: 'visible',
      opacity: 1,
      duration: .7,
    })
    .from(self.$navigationPrimaryItems, {
      duration: 0.8,
      opacity: 0,
      y: 20,
      stagger: 0.08,
    }, "<.2")
    .from(self.$navigationSecondaryItems, {
      duration: 0.8,
      opacity: 0,
      y: 10,
      stagger: 0.08,
    }, "<.2");
};

ModalHeader.prototype.toggleState = function () {
  const self = this;

  self.options.isToggled = !self.options.isToggled;
};

ModalHeader.prototype.toggleOn = function (speed = 1) {
  const self = this;

  // if (self.options.isToggled || self.animationTl.isActive()) return;

  self.animationTl.timeScale(speed).play();
};

ModalHeader.prototype.toggleOff = function (speed = 2) {
  const self = this;

  // if (!self.options.isToggled || self.animationTl.isActive()) return;

  self.animationTl.timeScale(speed).reverse();
};

ModalHeader.prototype.toggle = function () {
  const self = this;

  self.animationTl.reversed() ? self.toggleOn() : self.toggleOff();
};

export default ModalHeader;