import PubSub from "underpub-js";

var QuoteStandard = function (options) {

  this.options = {
    $el: false,
    baseClass: 'mol-QuoteStandard',
  };

  $.extend(true, this.options, options);

};

QuoteStandard.prototype.init = function () {

  var self = this;

  self.$items = self.options.$el.find(`.${self.options.baseClass}__items`);
  self.$dots = self.options.$el.find(`.${self.options.baseClass}__dots`);

  self.$items.slick({
    arrows: false,
    fade: true,
    dots: true,
    appendDots: self.$dots,
    autoplay: true,
    autoplaySpeed: 3000,
  });

};

export default QuoteStandard;