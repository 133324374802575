import PubSub from "underpub-js";

var Iframe = function (options) {

  this.options = {
    $el: false,
    baseClass: 'el-Iframe',
  };

  $.extend(true, this.options, options);

};

Iframe.prototype.init = function () {

  var self = this;

  self.$coverImage = self.options.$el.find(`.${self.options.baseClass}__cover-image`);
  self.$iframe = self.options.$el.find(`.${self.options.baseClass}__iframe`);

  self.$coverImage.on("click", () => {
    self.options.$el.attr("data-active", "true");
  })

};

export default Iframe;