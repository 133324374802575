import PubSub from "underpub-js";

var ListingUnits = function(options) {

	this.options = {
		$el: false,
		baseClass: 'mol-ListingUnits',
	};

	$.extend(true, this.options, options);

};

ListingUnits.prototype.init = function() {

	var self = this;

};

export default ListingUnits;
