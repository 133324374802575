import PubSub from "underpub-js";

var ImageHidden = function (options) {

  this.options = {
    $el: false,
    baseClass: 'atom-ImageHidden',
  };

  $.extend(true, this.options, options);

};

ImageHidden.prototype.init = function () {

  var self = this;

};

export default ImageHidden;
