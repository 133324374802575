import PubSub from "underpub-js";

var MapStandard = function(options) {

	this.options = {
		$el: false,
		baseClass: 'mol-MapStandard',
	};

	$.extend(true, this.options, options);

};

MapStandard.prototype.init = function() {

	var self = this;

};

export default MapStandard;
