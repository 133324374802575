import '../styles/app.scss';

window.easings = {};
window.easings.ease = "cubicBezier(0.25, 0.46, 0.45, 0.94)";
window.easings.andrew = "cubicBezier(0.77, 0, 0.175, 1)";
window.easings.feebles1 = "cubicBezier(.8,.03,.25,1)";
window.easings.feebles2 = "cubicBezier(.72,.01,.25,1)";
window.easings.ease2 = "cubicBezier(.48,.1,.48,.9)";

// Getting our breakpoint for javascript
window.Breakpoint = {};

Breakpoint.refreshValue = function () {
  this.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
};

$(window).resize(function () {
  Breakpoint.refreshValue();
}).resize();


// Libs
import PubSub from "underpub-js";
import Sitewide from "./classes/Sitewide";
import ComponentsLoader from "./classes/ComponentsLoader";

// Components Import -- DO NOT REMOVE COMMENT!
import ButtonIcon from "../../templates/_components/atoms/Buttons/ButtonIcon/ButtonIcon";
import Iframe from "../../templates/_components/elements/Media/Iframe/Iframe";
import IframeCentered from "../../templates/_components/molecules/Media/IframeCentered/IframeCentered";
import UnitTypeInformation from "../../templates/_components/molecules/UnitType/UnitTypeInformation/UnitTypeInformation";
import MediaSingle from "../../templates/_components/molecules/Media/MediaSingle/MediaSingle";
import BreakpointIndicator from "../../templates/_components/atoms/Global/BreakpointIndicator/BreakpointIndicator";
import PopupUnit from "../../templates/_components/elements/Popup/PopupUnit/PopupUnit";
import FloorPlansViewer from "../../templates/_components/molecules/FloorPlans/FloorPlansViewer/FloorPlansViewer";
import CardUnitType from "../../templates/_components/elements/Cards/CardUnitType/CardUnitType";
import ListingUnitTypes from "../../templates/_components/molecules/Listings/ListingUnitTypes/ListingUnitTypes";
import ModalUnit from "../../templates/_components/molecules/Modals/ModalUnit/ModalUnit";
import ListingUnits from "../../templates/_components/molecules/Listings/ListingUnits/ListingUnits";
import ModalForm from "../../templates/_components/molecules/Modals/ModalForm/ModalForm";
import ModalHeader from "../../templates/_components/molecules/Modals/ModalHeader/ModalHeader";
import FooterNavigation from "../../templates/_components/elements/Global/FooterNavigation/FooterNavigation";
import Footer from "../../templates/_components/molecules/Global/Footer/Footer";
import HeaderNavigation from "../../templates/_components/elements/Global/HeaderNavigation/HeaderNavigation";
import Header from "../../templates/_components/molecules/Global/Header/Header";
import MapStandard from "../../templates/_components/molecules/Maps/MapStandard/MapStandard";
import Map from "../../templates/_components/elements/Maps/Map/Map";
import HeroComplex from "../../templates/_components/molecules/Heros/HeroComplex/HeroComplex";
import MediaTakeover from "../../templates/_components/elements/Media/MediaTakeover/MediaTakeover";
import HeroStandard from "../../templates/_components/molecules/Heros/HeroStandard/HeroStandard";
import HeroBasic from "../../templates/_components/molecules/Heros/HeroBasic/HeroBasic";
import CallToActionFormInset from "../../templates/_components/molecules/CallToActions/CallToActionFormInset/CallToActionFormInset";
import Form from "../../templates/_components/elements/Forms/Form/Form";
import CallToActionForm from "../../templates/_components/molecules/CallToActions/CallToActionForm/CallToActionForm";
import CallToActionStandard from "../../templates/_components/molecules/CallToActions/CallToActionStandard/CallToActionStandard";
import ContentTextMedia from "../../templates/_components/molecules/Content/ContentTextMedia/ContentTextMedia";
import ContentTextTypeFour from "../../templates/_components/molecules/Content/ContentTextTypeFour/ContentTextTypeFour";
import ContentTextTypeThree from "../../templates/_components/molecules/Content/ContentTextTypeThree/ContentTextTypeThree";
import ContentTextTypeTwo from "../../templates/_components/molecules/Content/ContentTextTypeTwo/ContentTextTypeTwo";
import ContentTextTypeOne from "../../templates/_components/molecules/Content/ContentTextTypeOne/ContentTextTypeOne";
import QuoteStandard from "../../templates/_components/molecules/Quotes/QuoteStandard/QuoteStandard";
import CardFaq from "../../templates/_components/elements/Cards/CardFaq/CardFaq";
import ListingFaq from "../../templates/_components/molecules/Listings/ListingFaq/ListingFaq";
import CardInfo from "../../templates/_components/elements/Cards/CardInfo/CardInfo";
import ListingInfo from "../../templates/_components/molecules/Listings/ListingInfo/ListingInfo";
import MediaTrio from "../../templates/_components/molecules/Media/MediaTrio/MediaTrio";
import MediaDuo from "../../templates/_components/molecules/Media/MediaDuo/MediaDuo";
import CarouselFullWidth from "../../templates/_components/molecules/Media/CarouselFullWidth/CarouselFullWidth";
import CarouselCentered from "../../templates/_components/molecules/Media/CarouselCentered/CarouselCentered";
import VideoFullWidth from "../../templates/_components/molecules/Media/VideoFullWidth/VideoFullWidth";
import VideoCentered from "../../templates/_components/molecules/Media/VideoCentered/VideoCentered";
import VideoPlayer from "../../templates/_components/elements/Media/VideoPlayer/VideoPlayer";
import Carousel from "../../templates/_components/elements/Media/Carousel/Carousel";
import Pagination from "../../templates/_components/elements/Global/Pagination/Pagination";
import Breadcrumbs from "../../templates/_components/elements/Global/Breadcrumbs/Breadcrumbs";
import VideoLoop from "../../templates/_components/atoms/Media/VideoLoop/VideoLoop";
import ImageHidden from "../../templates/_components/atoms/Media/ImageHidden/ImageHidden";
import Hamburger from "../../templates/_components/atoms/Global/Hamburger/Hamburger";
import ButtonSecondary from "../../templates/_components/atoms/Buttons/ButtonSecondary/ButtonSecondary";
import CardFeature from "../../templates/_components/elements/Cards/CardFeature/CardFeature";
import ButtonPrimary from "../../templates/_components/atoms/Buttons/ButtonPrimary/ButtonPrimary";
import ListingFeatures from "../../templates/_components/molecules/Listings/ListingFeatures/ListingFeatures";
import Loader from "../../templates/_components/molecules/Global/Loader/Loader";

$(document).ready(function () {

  var components = [
    // Components Loader -- DO NOT REMOVE COMMENT!
{handle: ".atom-ButtonIcon", require: ButtonIcon },
{handle: ".el-Iframe", require: Iframe },
{handle: ".mol-IframeCentered", require: IframeCentered },
{handle: ".mol-UnitTypeInformation", require: UnitTypeInformation },
{handle: ".mol-MediaSingle", require: MediaSingle },
{handle: ".atom-BreakpointIndicator", require: BreakpointIndicator },
{handle: ".el-PopupUnit", require: PopupUnit },
{handle: ".mol-FloorPlansViewer", require: FloorPlansViewer },
{handle: ".el-CardUnitType", require: CardUnitType },
{handle: ".mol-ListingUnitTypes", require: ListingUnitTypes },
{handle: ".mol-ModalUnit", require: ModalUnit },
{handle: ".mol-ListingUnits", require: ListingUnits },
{handle: ".mol-ModalForm", require: ModalForm },
{handle: ".mol-ModalHeader", require: ModalHeader },
{handle: ".el-FooterNavigation", require: FooterNavigation },
{handle: ".mol-Footer", require: Footer },
{handle: ".el-HeaderNavigation", require: HeaderNavigation },
{handle: ".mol-Header", require: Header },
{handle: ".mol-MapStandard", require: MapStandard },
{handle: ".el-Map", require: Map },
{handle: ".mol-HeroComplex", require: HeroComplex },
{handle: ".el-MediaTakeover", require: MediaTakeover },
{handle: ".mol-HeroStandard", require: HeroStandard },
{handle: ".mol-HeroBasic", require: HeroBasic },
{handle: ".mol-CallToActionFormInset", require: CallToActionFormInset },
{handle: ".el-Form", require: Form },
{handle: ".mol-CallToActionForm", require: CallToActionForm },
{handle: ".mol-CallToActionStandard", require: CallToActionStandard },
{handle: ".mol-ContentTextMedia", require: ContentTextMedia },
{handle: ".mol-ContentTextTypeFour", require: ContentTextTypeFour },
{handle: ".mol-ContentTextTypeThree", require: ContentTextTypeThree },
{handle: ".mol-ContentTextTypeTwo", require: ContentTextTypeTwo },
{handle: ".mol-ContentTextTypeOne", require: ContentTextTypeOne },
{handle: ".mol-QuoteStandard", require: QuoteStandard },
{handle: ".el-CardFaq", require: CardFaq },
{handle: ".mol-ListingFaq", require: ListingFaq },
{handle: ".el-CardInfo", require: CardInfo },
{handle: ".mol-ListingInfo", require: ListingInfo },
{handle: ".mol-MediaTrio", require: MediaTrio },
{handle: ".mol-MediaDuo", require: MediaDuo },
    {
      handle: ".mol-CarouselFullWidth",
      require: CarouselFullWidth
    },
    {
      handle: ".mol-CarouselCentered",
      require: CarouselCentered
    },
    {
      handle: ".mol-VideoFullWidth",
      require: VideoFullWidth
    },
    {
      handle: ".mol-VideoCentered",
      require: VideoCentered
    },
    {
      handle: ".el-VideoPlayer",
      require: VideoPlayer
    },
    {
      handle: ".el-Carousel",
      require: Carousel
    },
    {
      handle: ".el-Pagination",
      require: Pagination
    },
    {
      handle: ".el-Breadcrumbs",
      require: Breadcrumbs
    },
    {
      handle: ".atom-VideoLoop",
      require: VideoLoop
    },
    {
      handle: ".atom-ImageHidden",
      require: ImageHidden
    },
    {
      handle: ".atom-Hamburger",
      require: Hamburger
    },
    {
      handle: ".atom-ButtonSecondary",
      require: ButtonSecondary
    },
    {
      handle: ".el-CardFeature",
      require: CardFeature
    },
    {
      handle: ".atom-ButtonPrimary",
      require: ButtonPrimary
    },
    {
      handle: ".mol-ListingFeatures",
      require: ListingFeatures
    },
    {
      handle: ".mol-Loader",
      require: Loader
    },
  ];

  var sitewide = new Sitewide();
  sitewide.init();

  // Getting our components loading going
  var componentsLoader = new ComponentsLoader({
    components: components
  });
  componentsLoader.initScreen();

  // setting our breakpoint value
  Breakpoint.refreshValue();

});