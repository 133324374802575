import PubSub from 'underpub-js';
import gsap from 'gsap';

var Loader = function (options) {
  this.options = {
    $el: false,
    baseClass: 'mol-Loader',
    hasLoaded: false,
  };

  $.extend(true, this.options, options);
};

Loader.prototype.init = function () {
  const self = this;

  self.$icon = self.options.$el.find(`.${self.options.baseClass}__icon`);

  self.initLoader();
  self.initLoaderAnimation();
  self.initLoaderOutAnimation();

  $(window).on('load', () => self.handleWindowLoad());

  setTimeout(() => !self.options.hasLoaded && $(window).trigger('load'), 2000);

};

Loader.prototype.initLoader = function () {
  const self = this;

  gsap.timeline()
    .set('body', {
      opacity: 1,
    }, "<");
};

Loader.prototype.killLoader = function () {
  const self = this;

  self.options.$el.hide();
  $('html').removeClass('loading');
  gsap.set('body', {
    clearProps: "opacity"
  })
  self.killLoaderAnimation();
};

Loader.prototype.initLoaderAnimation = function () {
  const self = this;

  self.loaderTimeline = gsap
    .timeline({
      paused: true,
      defaults: {
        ease: 'expo.out',
      },
    })
    .fromTo(
      self.$icon, {
        xPercent: -50,
        opacity: 0,
      }, {
        xPercent: 1,
        opacity: 1,
        duration: 1
      },
    );

  self.playLoaderAnimation();
};

Loader.prototype.initLoaderOutAnimation = function () {
  const self = this;

  self.loaderOutTimeline = gsap
    .timeline({
      delay: 1,
      paused: true,
      defaults: {
        ease: 'expo.out',
      },
      onStart: () => PubSub.publish('loaded'),
      onComplete: () => self.killLoader(),
    })
    .set(self.options.$el, {
      pointerEvents: "none",
    })
    .to(self.$icon, {
      ease: "back.out(1.5)",
      duration: 0.4,
      opacity: 0,
      xPercent: 50,
    })
    .to(
      self.options.$el, {
        duration: 1.5,
        opacity: 0,
      }, "-=.2"
    );
};

Loader.prototype.playLoaderAnimation = function () {
  const self = this;

  self.loaderTimeline.play();
};

Loader.prototype.killLoaderAnimation = function () {
  const self = this;

  self.loaderTimeline.kill();
};

Loader.prototype.playLoaderOutAnimation = function () {
  const self = this;

  self.loaderOutTimeline.play();
};

Loader.prototype.handleWindowLoad = function () {
  const self = this;

  self.options.hasLoaded = true;
  self.playLoaderOutAnimation();
};

export default Loader;