import PubSub from 'underpub-js';
import mapboxgl from 'mapbox-gl';
import * as turf from 'turf';
import ImgixClient from "@imgix/js-core";

const client = new ImgixClient({
  domain: 'amplify-kentonhouse.imgix.net',
});

var Map = function (options) {
  this.options = {
    $el: false,
    baseClass: 'el-Map',
    settings: {
      style: 'mapbox://styles/authenticff/ckyz0ix5d001515l0aduwqjv0',
      center: [-96, 40],
      zoom: 12,
    },
    COLORS: {
      BLACK: '#231f20',
      WHITE: '#faf8f2',
      PRIMARY: '#636d4d',
      SECONDARY: '#947d37'
    }
  };

  $.extend(true, this.options, options);
};

Map.prototype.init = function () {
  const self = this;

  self.$map = self.options.$el;
  self.id = self.options.$el.attr('id');
  self.data = window[`MapData_${self.id}`]
  self.zoom = self.data.zoom;
  self.geoJson = self.data.geoJson;
  self.features = self.geoJson.features;
  self.featuredFeature = self.features.filter(f => f.properties.isFeatured == true)[0] || null;

  if (typeof self.zoom === "number") {
    self.options.settings.zoom = self.zoom;
  }

  if (self.featuredFeature && typeof self.featuredFeature !== undefined) {
    self.options.settings.center = self.featuredFeature.geometry.coordinates;
  } else if (typeof self.features !== undefined && self.features.length) {
    self.options.settings.center = self.features[0].geometry.coordinates;
  }

  self.initMap();
};

Map.prototype.initMap = function () {
  const self = this;

  if (!self.features.length) return;

  mapboxgl.accessToken =
    'pk.eyJ1IjoiYXV0aGVudGljZmYiLCJhIjoiUmtjWTY2byJ9.QeHZuWdaMRJlobq_RgXngw';

  self.map = new mapboxgl.Map({
    container: self.id,
    style: self.options.settings.style,
    zoom: self.options.settings.zoom,
    center: self.options.settings.center,
  });

  const boundingBox = turf.bbox({
    type: 'FeatureCollection',
    features: self.features,
  });

  self.map.scrollZoom.disable();
  self.map.doubleClickZoom.disable();
  this.map.addControl(new mapboxgl.NavigationControl());

  const boundingBoxPaddingValue = $(window).width() >= 768 ? 200 : 100;
  const boundingBoxPadding = {
    top: boundingBoxPaddingValue,
    bottom: boundingBoxPaddingValue,
    left: boundingBoxPaddingValue,
    right: boundingBoxPaddingValue,
  };


  if (self.features.length > 1) {
    self.map.fitBounds(boundingBox, {
      center: self.options.settings.center,
      padding: boundingBoxPadding,
      zoom: self.options.settings.zoom,
    });
  } else if (self.features.length == 1) {
    self.map.flyTo({
      center: self.options.settings.center,
      zoom: self.options.settings.zoom,
    });
  }

  self.map.on('load', function () {

    self.features.forEach(feature => {
      const featureIcon = feature.properties.icon;

      if (typeof featureIcon === "object" && featureIcon !== null) {
        const featureIconPath = featureIcon.path;
        const featureIconTitle = featureIcon.title;
        let featureIconUrl;
        let featureIconImgixUrl;

        if (typeof featureIconPath === "string" && featureIconPath.length > 0) {
          featureIconImgixUrl = client.buildURL(`/images/${featureIconPath}`, {
            width: 40,
            height: 40,
          })
        }

        if (featureIconImgixUrl.length) {
          featureIconUrl = featureIconImgixUrl;
        } else {
          featureIconUrl = featureIcon.url;
        }

        if (typeof featureIconUrl === "string") {
          self.map.loadImage(featureIconUrl, (error, image) => {
            if (error) throw error;

            if (!self.map.hasImage(featureIconTitle)) {
              self.map.addImage(featureIconTitle, image);
            }
          })
        }
      }
    })

    self.map.addSource('locations', {
      type: 'geojson',
      data: self.geoJson,
      cluster: true,
      clusterMaxZoom: 10,
      clusterRadius: 50,
    });

    self.map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'locations',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          self.options.COLORS.PRIMARY,
          20,
          self.options.COLORS.SECONDARY,
          50,
          self.options.COLORS.BLACK,
        ],
        'circle-opacity': ['step', ['get', 'point_count'], 1, 20, 1, 50, 1],
        'circle-radius': ['step', ['get', 'point_count'], 20, 20, 30, 50, 40],
      },
    });

    self.map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'locations',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': ['step', ['get', 'point_count'], 12, 20, 15, 50, 18],
      },
      paint: {
        'text-color': [
          'step',
          ['get', 'point_count'],
          self.options.COLORS.WHITE,
          20,
          self.options.COLORS.BLACK,
          50,
          self.options.COLORS.WHITE,
        ],
      },
    });

    self.map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'locations',
      filter: [
        "all",
        ['!', ['has', 'point_count']],
        ['!=', ['get', 'isFeatured'], true]
      ],
      paint: {
        'circle-color': self.options.COLORS.PRIMARY,
        'circle-opacity': 1,
        'circle-radius': 20,
      },
    });

    self.map.addLayer({
      id: 'unclustered-point-image',
      type: 'symbol',
      source: 'locations',
      layout: {
        'icon-image': ['get', 'title', ['get', "icon"]],
        'icon-size': .5,
      }
    });

    self.map.addLayer({
      id: 'unclustered-point-featured',
      type: 'circle',
      source: 'locations',
      filter: [
        "all",
        ['==', ['get', 'isFeatured'], true]
      ],
      paint: {
        'circle-color': self.options.COLORS.BLACK,
        'circle-opacity': 1,
        'circle-radius': 20,
      },
    });

    self.map.addLayer({
      id: 'unclustered-point-featured-image',
      type: 'symbol',
      source: 'locations',
      filter: [
        "all",
        ['==', ['get', 'isFeatured'], true]
      ],
      layout: {
        'icon-image': ['get', 'title', ['get', "icon"]],
        'icon-size': .5,
      }
    });

    self.map.on('click', 'clusters', function (e) {
      const features = self.map.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      });
      const clusterId = features[0].properties.cluster_id;
      self.map
        .getSource('locations')
        .getClusterExpansionZoom(clusterId, function (err, zoom) {
          if (err) return;

          self.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    });

    self.map.on('click', ['unclustered-point-featured', 'unclustered-point'], function (e) {
      const marker = e.features[0];
      const markerCoordinates = marker.geometry.coordinates;
      const markerProperties = marker.properties;
      const popupClass = `${self.options.baseClass}__marker__popup`;
      const markerHeading = markerProperties.heading;
      const markerCopy = markerProperties.copy;

      const popupMarkup = `<div class="${popupClass}">
        <div class="${popupClass}__content">
          <h5 class="${popupClass}__heading">
            ${markerHeading}
          </h5>
          <p class="${popupClass}__copy">
            ${markerCopy}
          </p>
        </div>
      </div>`;

      new mapboxgl.Popup()
        .setLngLat(markerCoordinates)
        .setOffset(15)
        .setHTML(popupMarkup)
        .addTo(self.map);
    });

    self.map.on('mouseenter', 'clusters', function () {
      self.map.getCanvas().style.cursor = 'pointer';
    });

    self.map.on('mouseleave', 'clusters', function () {
      self.map.getCanvas().style.cursor = '';
    });
  });
};

export default Map;