import PubSub from "underpub-js";
import Plyr from 'plyr/dist/plyr.polyfilled.js';

var VideoPlayer = function (options) {

  this.options = {
    $el: false,
    baseClass: 'el-VideoPlayer',
    playing: false,
  };

  $.extend(true, this.options, options);

};

VideoPlayer.prototype.init = function () {

  var self = this;

  self.$player = self.options.$el.find(`.${self.options.baseClass}__player`);
  self.$coverImage = self.options.$el.find(`.${self.options.baseClass}__cover-image`);
  self.$playButton = self.options.$el.find(`.${self.options.baseClass}__play-button`);

  self.player = new Plyr(this.$player, {
    enabled: true,
    controls: ["progress"],
    settings: ["captions", "quality", "speed", "loop"],
    autoplay: false,
    muted: false,
    clickToPlay: true,
    hideControls: false,
    resetOnEnd: false,
    ratio: "16:9"
  });

  const handlePlayPauseClick = () => {
    self.options.$el.attr("data-playing", !self.options.playing);

    if (self.options.playing) {
      self.player.pause();
    } else {
      self.player.play();
    }
  }

  self.$playButton.on("click", handlePlayPauseClick);

  self.$coverImage.on("click", handlePlayPauseClick);

  self.player.on("playing", e => {
    const instance = e.detail.plyr;

    self.options.playing = true;
  });

  self.player.on("pause", e => {
    const instance = e.detail.plyr;

    self.options.playing = false;
  });

};

export default VideoPlayer;