import PubSub from "underpub-js";

var MediaSingle = function(options) {

	this.options = {
		$el: false,
		baseClass: 'mol-MediaSingle',
	};

	$.extend(true, this.options, options);

};

MediaSingle.prototype.init = function() {

	var self = this;

};

export default MediaSingle;
