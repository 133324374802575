import PubSub from 'underpub-js';
import gsap from 'gsap';

var Hamburger = function (options) {
  this.options = {
    $el: false,
    baseClass: 'atom-Hamburger',
    isToggled: false,
  };

  $.extend(true, this.options, options);
};

Hamburger.prototype.init = function () {
  const self = this;

  self.initAnimation();

  self.options.$el.on("click", () => {
    PubSub.publish("Hamburger:toggle");
  })

  PubSub.subscribe("Hamburger:toggle", () => self.toggle());
  PubSub.subscribe("Hamburger:toggleOn", () => self.toggleOn());
  PubSub.subscribe("Hamburger:toggleOff", () => self.toggleOff());
  PubSub.subscribe('Sitewide:resize', () => self.toggleOff())
};

Hamburger.prototype.initAnimation = function () {
  const self = this;

  const $hamburger = self.options.$el;
  const $line = $hamburger.find('.line');
  const $top = $hamburger.find('.top');
  const $topLine = $top.find($line);
  const $bottom = $hamburger.find('.bottom');
  const $bottomLine = $bottom.find($line);
  const $middle = $hamburger.find('.middle');

  self.animationTl = gsap
    .timeline({
      paused: true,
      reversed: true,
      onComplete: () => self.toggleState(),
      onReverseComplete: () => self.toggleState(),
    })
    .to($line, {
      duration: 0.2,
      width: 0,
      stagger: 0.1,
    })
    .set([$top, $bottom], {
      left: "10%",
    })
    .set([$top, $middle, $bottom], {
      scaleX: 1,
    })
    .set($top, {
      top: "-3%"
    })
    .set($bottom, {
      bottom: "-3%"
    })
    .set($topLine, {
      rotation: 45,
    })
    .set($hamburger, {
      scale: 1.2,
      xPercent: 10,
    })
    .set($bottomLine, {
      rotation: -45
    })
    .add(() => self.options.$el.toggleClass(`${self.options.baseClass}--active`))
    .to([$topLine, $bottomLine], {
      duration: 0.25,
      width: '100%',
      stagger: 0.25,
    });
};

Hamburger.prototype.toggleState = function () {
  const self = this;

  self.options.isToggled = !self.options.isToggled;
};

Hamburger.prototype.toggleOn = function (speed = 1) {
  const self = this;

  // if (self.options.isToggled || self.animationTl.isActive()) return;

  PubSub.publish("ModalHeader:toggleOn");
  self.animationTl.timeScale(speed).play();
};

Hamburger.prototype.toggleOff = function (speed = 1.4) {
  const self = this;

  // if (!self.options.isToggled || self.animationTl.isActive()) return;

  PubSub.publish("ModalHeader:toggleOff");
  self.animationTl.timeScale(speed).reverse();
};

Hamburger.prototype.toggle = function () {
  const self = this;

  !self.options.isToggled ? self.toggleOn() : self.toggleOff();
};

export default Hamburger;