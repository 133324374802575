import PubSub from "underpub-js";

var ContentTextMedia = function(options) {

	this.options = {
		$el: false,
		baseClass: 'mol-ContentTextMedia',
	};

	$.extend(true, this.options, options);

};

ContentTextMedia.prototype.init = function() {

	var self = this;

};

export default ContentTextMedia;
